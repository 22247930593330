import * as _jszip2 from "jszip";

var _jszip = "default" in _jszip2 ? _jszip2.default : _jszip2;

var exports = {};
const JSZip = _jszip;

exports = async buffer => {
  const zip = new JSZip();
  await zip.loadAsync(buffer);
  const files = zip.file(/.+/);
  const out = {};
  await Promise.all(files.map(async a => {
    let result;

    if (a.name.slice(-3).toLowerCase() === "shp" || a.name.slice(-3).toLowerCase() === "dbf") {
      result = await a.async("nodebuffer");
    } else {
      result = await a.async("text");
    }

    out[a.name] = result;
  }));
  return out;
};

export default exports;